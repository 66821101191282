<template>
  <customer-page :is-loading="isLoading">
    <template v-slot:page-headline>{{ $t('Ihr Vertrag Nr.') }} <span v-if="contract">{{contract.contractNumber}}</span></template>
    <template v-slot:page-subline v-if="contractYear">{{ $t('Vertragsjahr') }} {{contractYear}} - {{ $t(`Vertragsstatus abgeschlossen`) }}</template>
    <template v-slot:page-content-container>
      <div class="container pt-2">
        <div class="row">
          <div class="col-12 col-lg-12">
            <div class="action-bar text-center">

                  <ul class="clean-list h-list">
                    <li v-for="action in contractPeriodActions">
                      <router-link v-if="action == 'addContractPeriodQuantity'" class="btn btn-primary" :to="{ name: 'contract_period_quantity_add', params: { contractPeriodId: contractPeriod.id }}">&gt; {{ $t(`Nachmeldung`) }}</router-link>
                      <router-link v-if="action == 'finalContractPeriod'" class="btn btn-primary" :to="{ name: 'contract_period_final', params: { contractPeriodId: contractPeriod.id }}">&gt; {{ $t(`Jahresabschluss`) }}</router-link>
                      <router-link v-if="action == 'cancelContract'" class="btn btn-primary" :to="{ name: 'contract_cancel', params: { contractPeriodId: contractPeriod.id }}">&gt; {{ $t(`Vertrag kündigen`) }}</router-link>
                    </li>
                  </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="container pt-4">
        <div class="container-content">
          <section class="section-block" v-if="contractPeriod">
            <h2>{{ $t(`Mengen`) }} {{contractPeriod.contractYear}}:</h2>
            <data-table :columns="lastContractPeriodQuantityColumns" v-if="contractPeriod.lastContractPeriodQuantity" :rows="contractPeriod.lastContractPeriodQuantity.quantityItems" :is-loading="isLoading">
              <template v-slot:column-quantity="{ row }">
                {{ $filters.formatNumber(row.quantity, 4) }}
              </template>
            </data-table>
          </section>

          <section class="section-block pt-4" v-if="contractPeriodDocuments && contractPeriodDocuments.length > 0">
            <h2>{{ $t('Ihre Dokumente') }}:</h2>
            <ul class="clean-list h-list contract-documents-list">
              <li class="contract-document" v-for="document in contractPeriodDocuments">
                <a :href="document.documentDownloadLink" target="_blank">
                  <span v-if="document.documentTypeName == 'ekoPunkGoOrderQuantityConfirmation'">{{ $t(`Mengenbestätigung`) }}</span>
                  <span v-else-if="document.documentTypeName == 'ekoPunkGoOrderParticipationConfirmation'">{{ $t(`Teilnahmebestätigung`) }}</span>
                  <span v-else-if="document.documentTypeName == 'ekoPunkGoCo2Certificate'">{{ $t(`CO2 Zertifikat`) }}</span>
                  <span v-else>{{document.fileName}}</span>
                </a>
              </li>
              <li class="contract-document" v-if="showSeals && locale == 'de-DE'">
                <a href="#" @click="onDownloadSiegel($event, require('../../../../assets/images/kunden-siegel/deutsch/24_05_EKO-PUNKT-Siegel_Nutzungsbedingungen.pdf'), '24_05_EKO-PUNKT-Siegel_Nutzungsbedingungen.pdf')">
                  {{ $t('Nutzungsbedingungen EKO-PUNKT-Siegel') }}
                </a>
              </li>
              <li class="contract-document" v-if="showSeals && (locale == 'en-GB' || locale == 'zh-CN')">
                <a href="#" @click="onDownloadSiegel($event, require('../../../../assets/images/kunden-siegel/englisch/24-05_EKO-PUNKT-Seal_Tearms_of_use.pdf'), '24-05_EKO-PUNKT-Seal_Tearms_of_use.pdf')">
                  {{ $t('Nutzungsbedingungen EKO-PUNKT-Siegel') }}
                </a>
              </li>
            </ul>
          </section>

          <section class="section-block pt-4" v-if="!isLoading && showSeals">
            <h2>{{ $t('Ihre EKO-PUNKT Siegel') }}:</h2>
            <ul class="clean-list h-list contract-documents-list" v-if="locale == 'de-DE'">
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/deutsch/EKO-PUNKT_Siegel_Verpackungslizenz_DE_rot_72dpi.png')" fileName="EKO-PUNKT_Siegel_Verpackungslizenz_DE_rot_72dpi.png" />
              </li>
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/deutsch/EKO-PUNKT_Siegel_Verpackungslizenz_DE_rot_1200dpi.jpg')" fileName="EKO-PUNKT_Siegel_Verpackungslizenz_DE_rot_1200dpi.jpg" />
              </li>
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/deutsch/EKO-PUNKT_Siegel_Verpackungslizenz_DE_schwarz_72dpi.png')" fileName="EKO-PUNKT_Siegel_Verpackungslizenz_DE_schwarz_72dpi.png" />
              </li>
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/deutsch/EKO-PUNKT_Siegel_Verpackungslizenz_DE_schwarz_1200dpi.jpg')" fileName="EKO-PUNKT_Siegel_Verpackungslizenz_DE_schwarz_1200dpi.jpg" />
              </li>
            </ul>
            <ul class="clean-list h-list contract-documents-list" v-if="locale == 'en-GB' || locale == 'zh-CN'">
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/englisch/EKO-PUNKT_Seal_Packaging_Licensing_EN_red_72dpi.png')" fileName="EKO-PUNKT_Seal_Packaging_Licensing_EN_red_72dpi.png" />
              </li>
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/englisch/EKO-PUNKT_Seal_Packaging_Licensing_EN_red_1200dpi.jpg')" fileName="EKO-PUNKT_Seal_Packaging_Licensing_EN_red_1200dpi.jpg" />
              </li>
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/englisch/EKO-PUNKT_Seal_Packaging_Licensing_EN_black_72dpi.png')" fileName="EKO-PUNKT_Seal_Packaging_Licensing_EN_black_72dpi.png" />
              </li>
              <li>
                <KundenSiegelImage :src="require('../../../../assets/images/kunden-siegel/englisch/EKO-PUNKT_Seal_Packaging_Licensing_EN_black_1200dpi.jpg')" fileName="EKO-PUNKT_Seal_Packaging_Licensing_EN_black_1200dpi.jpg" />
              </li>
            </ul>
          </section>

          <section class="section-block pt-4" v-if="contractPeriodQuantities">
            <h2>{{ $t(`Ihre Aufträge`) }}:</h2>
            <data-table :columns="contractPeriodQuantityColumns" :rows="contractPeriodQuantities" :is-loading="isLoadingQuantities">
              <template v-slot:column-quantityType="{ row }">
                <div v-if="row.quantityType == 'new'">{{ $t(`Erstmeldung`) }}</div>
                <div v-else-if="row.quantityType == 'add'">{{ $t(`Nachmeldung`) }}</div>
                <div v-else-if="row.quantityType == 'final'">{{ $t(`Jahresabschluss`) }}</div>
                <div v-else>{{row.quantityType}}</div>
              </template>
              <template v-slot:column-price.netPrice="{ row }">
                {{ $filters.formatNumber(row.price.netPrice) }} &euro;
              </template>
              <template v-slot:column-createdAt="{ row }">
                {{ $d(row.createdAt, 'short') }}
              </template>
            </data-table>
          </section>

          <section class="section-block pt-4" v-if="contractPeriodOrders">
            <h2>{{ $t(`Ihre Rechnungen`) }}:</h2>
            <data-table :columns="contractPeriodOrderColumns" :rows="contractPeriodOrders" :is-loading="isLoadingOrders">
              <template v-slot:column-order.price.netPrice="{ row }">
                {{ $filters.formatNumber(row.order.price.netPrice) }} &euro;
              </template>
              <template v-slot:column-order.createdAt="{ row }">
                {{ $d(row.order.createdAt, 'short') }}
              </template>
              <template v-slot:column-orderDownloadLink="{ row }">
                <a :href="row.orderDownloadLink" target="_blank">{{ $t(`herunterladen`) }}</a>
              </template>
            </data-table>
          </section>
        </div>
      </div>
    </template>
  </customer-page>
</template>

<script>
import LoaderOverlay from "../../component/utils/loader-overlay";
import CustomerPage from "../customer-page";
import DataTable from "../../component/table/dataTable";
import ContractYearSelect from "../../component/form/contract-year-select";
import KundenSiegelImage from "../../component/utils/kunden-siegel-image.vue";
export default {
  name: "contract-view",
  components: {KundenSiegelImage, ContractYearSelect, DataTable, CustomerPage, LoaderOverlay},
  data() {
    return {
      isLoading: false,
      isLoadingQuantities: false,
      isLoadingDocuments: false,
      isLoadingOrders: false,
      contract: null,
      contractPeriod: null,
      contractYear: null,
      contractPeriodQuantities: null,
      contractPeriodDocuments: null,
      contractPeriodOrders: null,
      contractPeriodActions: null,
      showSeals: true
    }
  },
  created() {
    this.contractYear = this.$route.params.contractYear;
    this.loadContractPeriod();
  },
  computed: {
    locale() {
      return easyLizeApp.locale;
    },
    lastContractPeriodQuantityColumns() {
      return [
        {
          field: 'label',
          label: this.$t('Material')
        },
        {
          field: 'quantity',
          label: this.$t('Menge kg')
        }
      ];
    },
    contractPeriodQuantityColumns() {
      return [
        {
          field: 'contractPeriodQuantityNumber',
          label: this.$t('Auftrag Nr.')
        },
        {
          field: 'quantityType',
          label: this.$t('Auftrag')
        },
        {
          field: 'price.netPrice',
          label: this.$t('Betrag Netto')
        },
        {
          field: 'createdAt',
          label: this.$t('Datum')
        }
      ];
    },
    contractPeriodOrderColumns() {
      return [
        {
          field: 'order.orderNumber',
          label: this.$t('Rechnungs-Nr.')
        },
        {
          field: 'order.price.netPrice',
          label: this.$t('Betrag Netto')
        },
        {
          field: 'order.createdAt',
          label: this.$t('Datum')
        },
        {
          field: 'orderDownloadLink',
          label: ''
        }
      ];
    }
  },
  methods: {
    loadContractPeriod() {
      this.isLoading = true;
      easyLizeApp.apiClient.getContractContractYearPeriod((result) => {
        this.contract = result.contract;
        this.contractPeriod = result.contractPeriod;
        if(this.contractPeriod) {
          this.contractYear = this.contractPeriod.contractYear;
          this.loadContractPeriodQuantities();
          this.loadContractPeriodOrders();
          this.loadContractPeriodDocuments();
          this.loadContractPeriodActions();
        }
        this.isLoading = false;
      }, this.$route.params.contractId, this.contractYear);
    },
    loadContractPeriodQuantities() {
      this.isLoadingQuantities = true;
      easyLizeApp.apiClient.getContractPeriodQuantities((result) => {
        this.contractPeriodQuantities = result.elements;
        this.isLoadingQuantities = false;
      }, this.contractPeriod.id)
    },
    loadContractPeriodOrders() {
      this.isLoadingOrders = true;
      easyLizeApp.apiClient.getContractPeriodOrders((contractPeriodOrders) => {
        this.contractPeriodOrders = contractPeriodOrders;
        this.isLoadingOrders = false;
      }, this.contractPeriod.id)
    },
    loadContractPeriodDocuments() {
      this.isLoadingDocuments = true;
      easyLizeApp.apiClient.getContractPeriodDocuments((documents) => {
        this.contractPeriodDocuments = documents;
        this.isLoadingDocuments = false;
      }, this.contractPeriod.id)
    },
    loadContractPeriodActions() {
      easyLizeApp.apiClient.getContractPeriodActions((actions) => {
        this.contractPeriodActions = actions;
      }, this.contractPeriod.id)
    },
    onDownloadSiegel(event, fileUri, fileName) {
      const link = document.createElement('a');
      link.href = fileUri;
      link.download = fileName;
      document.body.appendChild(link);
      event.preventDefault();
      link.click();
      document.body.removeChild(link);
    },
    onContractYearChanged() {
      this.$router.push({ name: 'contract_view', params: { contractId: this.$route.params.contractId, contractYear: this.contractYear } });
      this.loadContractPeriod();
    }
  }
}
</script>

<style scoped>
.sigel-nutzungsbedingungen {
  cursor: pointer;
  font-size: 5rem;
  vertical-align: middle;
}
</style>