<template>
  <div class="form-check form-check-inline" v-for="contractYear in contractYears" v-if="!isLoading">
    <input class="form-check-input" type="radio" :name="`contractYear`" :id="`contractYear${contractYear.id}Radio`" :value="contractYear.contractYear" v-model="modelValue" @click="updateValue">
    <label class="form-check-label" :for="`contractYear${contractYear.id}Radio`">{{ contractYear.contractYear }}</label>
  </div>
</template>

<script>
import {Field} from "vee-validate";

export default {
  name: "contract-year-select",
  emits: [ 'update:modelValue', 'contractYear:changed' ],
  components: { Field },
  props: {
    modelValue: { required: true, default: null },
    rules: {
      type: [Object, String, Function],
      default: undefined,
    },
    getAll: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: false,
      contractYears: {}
    }
  },
  created() {
    this.isLoading = true;
    easyLizeApp.apiClient.getContractYears((contractYears) => {
      this.contractYears = contractYears;

      let currentContractYear = localStorage.getItem('currentContractYear') || new Date().getFullYear();
      if(this.$route.query.year) {
        currentContractYear = this.$route.query.year;
      }
      if(currentContractYear && contractYears.filter(c => c.contractYear == currentContractYear).length === 0) {
        currentContractYear = contractYears[contractYears.length - 1].contractYear;
      }
      if(!this.modelValue) {
        for(let i in contractYears) {
          if(contractYears[i].contractYear == currentContractYear || contractYears.length === 1) {
            this.changeContractYear(contractYears[i].contractYear);
          }
        }
      }
      this.isLoading = false;
    }, this.getAll);
  },
  methods: {
    changeContractYear(contractYear) {
      localStorage.setItem('currentContractYear', contractYear);
      this.$emit('update:modelValue', contractYear);
      this.$emit('contractYear:changed', contractYear);
    },
    updateValue(event) {
      this.changeContractYear(event.target.value);
    },
  }
}
</script>

<style scoped>

</style>